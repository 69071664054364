.bar-graph {
    display: flex;
    height: 35px;
    background-color: #f2f2f2;
  }
  
  .bars {
    background-color: #D96B2B;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-top: 3;
    color: #f2f2f2;
    width: 100%;
    border-left-width: 0;
    border-bottom-width: 0;
    border-top-width: 0;
    border-right-width: 0;
    border-style: solid;
    border-color: #d9d9d9;
  }
  